<ngx-loading-bar></ngx-loading-bar>
<mat-toolbar color="primary" class="mat-elevation-z2">
  <span>Collector</span>
  <span class="spacer"></span>
  <a mat-button routerLink="/dashboard" routerLinkActive="active">Dashboard</a>
  <a mat-button routerLink="/status" routerLinkActive="active">Status</a>
  <a mat-button routerLink="/zombies" routerLinkActive="active">Zombies</a>
  <a mat-button routerLink="/logs" routerLinkActive="active">Logs</a>
  <a mat-button routerLink="requests" routerLinkActive="active">Requests</a>
  <a mat-button routerLink="/chats" routerLinkActive="active">Chats</a>
  <a mat-button routerLink="/lag" routerLinkActive="active">Lag</a>
<!--  <a mat-button>Perf</a>-->
  <!--  <a mat-button>Storage</a>-->
  <a mat-button routerLink="/settings" routerLinkActive="active">Settings</a>
</mat-toolbar>
<div style="margin: 0 auto; width: 1300px; padding: 16px 0">
  <router-outlet></router-outlet>
</div>
