<div class="header">
  <div>
    <h1 style="margin: 0;">Zombies {{ itemsCount }}</h1>
    <span class="mat-hint" style="font-size: 14px">Online: {{ onlineCount }}</span>
  </div>
  <button mat-stroked-button style="margin-left: 16px" (click)="reloadFromApi()">
    <mat-icon>refresh</mat-icon>
    reload
  </button>
  <span class="spacer"></span>
  <mat-form-field appearance="fill">
    <mat-label>Колонки</mat-label>
    <mat-select [formControl]="columnsCtrl" multiple>
      <mat-select-trigger>
        {{ columnsCtrl.value ? columnsCtrl.value[0] : '' }}
        <span *ngIf="columnsCtrl.value?.length > 1" class="example-additional-selection">
        (+{{ columnsCtrl.value.length - 1 }} {{ columnsCtrl.value?.length === 2 ? 'other' : 'others' }})
      </span>
      </mat-select-trigger>
      <mat-option *ngFor="let column of columnsToDisplay" [value]="column">{{ column }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>


<div class="filter">

</div>
<div class="table-wrapper mat-elevation-z2">
  <table mat-table [dataSource]="dataForRender" matSort matSortActive="createdAt" matSortDisableClear
         matSortDirection="desc">

    <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>A</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.active" style="color: green; opacity: 0.5">check_circle</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Phone</th>
      <td mat-cell *matCellDef="let element"> {{ element._id }}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> User</th>
      <td mat-cell *matCellDef="let element">
        <img src="/api/zombies/{{element._id}}/photo" width="30" style="display: inline-block">
        <div class="mat-small mat-hint" style="display: inline-block; padding-left: 10px">
          {{ element.first_name }}
          <ng-container *ngIf="element.last_name"> - {{ element.last_name }}</ng-container>
          <ng-container *ngIf="element.username"><br><a href="https://t.me/{{element.username}}"
                                                        target="_blank">&#64;{{ element.username }}</a></ng-container>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Создан</th>
      <td mat-cell *matCellDef="let element"
          [title]="element.createdAt | date: 'full'"> {{ element.createdAt | date:'mediumDate' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="last_work_done">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear title="Последний успешный цыкл"> ПУЦ</th>
      <td mat-cell *matCellDef="let element"
          [title]="element.last_work_done | date: 'full'"> {{ element.last_work_done | date:'short' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="dialogs_count">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Діалогів/групп</th>
      <td mat-cell *matCellDef="let element"> {{ element.dialogs_count }}/{{ element.group_count }}</td>
    </ng-container>

    <ng-container matColumnDef="proxy">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Проксі</th>
      <td mat-cell *matCellDef="let element"> {{ element.proxy }}</td>
    </ng-container>

    <ng-container matColumnDef="avg_loop">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Avg Loop</th>
      <td mat-cell *matCellDef="let element"> {{ element.avg_loop }}</td>
    </ng-container>

    <ng-container matColumnDef="tags">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Tags</th>
      <td mat-cell *matCellDef="let element"> {{ element.tags }}</td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef width="70">#</th>
      <td mat-cell *matCellDef="let element">
        <a mat-stroked-button [routerLink]="['z', element._id]" target="_blank">
          <mat-icon>arrow_forward</mat-icon>
        </a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsCtrl.value.concat(['action'])"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsCtrl.value.concat(['action']);"></tr>
  </table>
  <mat-paginator [length]="itemsCount"
                 [pageSize]="100"
                 [pageSizeOptions]="[100, 500, 1000, 5000, 10000]"></mat-paginator>
</div>
