import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { Options, SeriesOptionsType } from "highcharts";
import * as Highcharts from "highcharts/highstock";
import { Chart } from "highcharts/highstock";
import exportData from "highcharts/modules/export-data";
import exporting from "highcharts/modules/exporting";
import offlineExporting from "highcharts/modules/offline-exporting";
import { DateTime } from "luxon";
import { configHighchart } from "../../../lib/chart";

configHighchart(Highcharts);
exportData(Highcharts);
exporting(Highcharts);
offlineExporting(Highcharts);

@Component({
  selector: "app-stats-chart",
  templateUrl: "./stats-chart.component.html",
  styleUrls: ["./stats-chart.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class StatsChartComponent implements OnInit {
  @Input() chartTitle!: string;
  @Input() description!: string;
  @Input() exportTitle!: string;
  @Input() aggregate!: string;
  @Input() series!: SeriesOptionsType[];

  emptyData = false;

  chart: Chart | null = null;

  @ViewChild("divElement", { static: true }) el!: ElementRef<HTMLDivElement>;
  @ViewChild("range", { static: true }) rangeEl!: ElementRef<HTMLDivElement>;

  @ViewChild("contextMenu") contextMenuTmpl!: TemplateRef<any>;

  constructor(private matBottomSheet: MatBottomSheet) {}

  async ngOnInit() {
    this.chart = await this.drawChart(this.el.nativeElement, this.rangeEl.nativeElement, this.series);
  }

  export(type: string) {
    if (type === "png") {
      this.chart!.exportChartLocal({
        type: "image/png"
      });
    }

    if (type === "csv") {
      this.chart!.downloadCSV();
    }

    if (type === "xls") {
      this.chart!.downloadXLS();
    }
    if (this.matBottomSheet) {
      this.matBottomSheet.dismiss();
    }
  }

  exportMenu() {
    this.matBottomSheet.open(this.contextMenuTmpl);
  }

  drawChart(el: HTMLDivElement, range: HTMLDivElement, series: any[]) {
    let series0Data = series[0].data;
    if (series0Data.length === 0) {
      this.emptyData = true;
      return Promise.resolve(null);
    }
    this.emptyData = false;
    let minDate = series0Data[0][0];
    let maxDate = series0Data[series0Data.length - 1][0];
    if (series0Data.length > 31) {
      minDate = series0Data[series0Data.length - 31][0];
    }

    // let minValue = Math.min(...series.map(v => v[1]));

    let chart = Highcharts.stockChart(el, {
      time: {
        useUTC: false
      },
      chart: {
        events: {
          load: function(e: any) {
            const rangeDate = `${Highcharts.dateFormat("%d %b %Y", e.target.xAxis[0].min)} - ${Highcharts.dateFormat(
              "%d %b %Y",
              e.target.xAxis[0].max
            )}`;
            range.innerHTML = rangeDate;
          }
        },
        style: {
          fontFamily: `Montserrat, 'Lucida Grande', 'Lucida Sans Unicode', Arial, Helvetica, sans-serif`
        }
      },
      rangeSelector: {
        enabled: false
      },
      xAxis: {
        ordinal: false,
        min: minDate,
        max: maxDate,
        type: "datetime",
        // labels: {
        //   format: '{value:%Y-%m-%d}'
        // }
        events: {
          setExtremes: function(e) {
            const rangeDate = `${Highcharts.dateFormat("%d %b %Y", e.min)} - ${Highcharts.dateFormat(
              "%d %b %Y",
              e.max
            )}`;
            range.innerHTML = rangeDate;
          }
        },
        labels: {
          align: "center"
          // format: '',
        }
      },
      yAxis: {
        opposite: false,
        left: 30,
        labels: {
          align: "left"
        },
        // min: minValue * 0.99,
        startOnTick: false
      },
      series: series,
      legend: {
        enabled: true,
        layout: "horizontal",
        align: "left",
        verticalAlign: "bottom",
        x: 0,
        y: -4,
        reversed: true,
        margin: 20,
        padding: 0,
        symbolRadius: 2,
        itemStyle: {
          fontWeight: "500"
        }
      },
      scrollbar: {
        enabled: true,
        liveRedraw: true,
        height: 0,
        buttonArrowColor: "#ffffff"
      },
      navigator: {
        enabled: true,
        maskFill: "rgba(60, 85, 165, 0.1)",
        maskInside: false,
        // outlineColor: 'rgba(115, 113, 115, 0.1)',
        outlineWidth: 0,
        height: 30,
        margin: 12,
        handles: {
          backgroundColor: "#C5D3E1",
          borderColor: "#ffffff",
          enabled: true,
          height: 38,
          lineWidth: 1 as any,
          symbols: ["url(/assets/highchart/handles-left.svg)", "url(/assets/highchart/handles-right.svg)"],
          width: 34
        },
        xAxis: {
          labels: {
            enabled: false
          },
          gridLineWidth: 0
        }
      },
      plotOptions: {
        column: {
          stacking: "normal",
          showInNavigator: true
        }
      },
      tooltip: {
        // xDateFormat: { day: "%A, %d %B", week: "%d %B", month: "%B %Y" }[this.aggregate],
        backgroundColor: "",
        borderWidth: 0,
        shadow: false,
        useHTML: true,
        split: false,
        distance: 8,
        pointFormat: `<span style="color:{point.color}">■</span> {series.name}: <b>{point.y}</b><br/>`
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false,
        fallbackToExportServer: true,
        filename: this.chartTitle + this.exportTitle + " - " + DateTime.local().toFormat("dd MMMM yyyy"),
        allowHTML: true,
        chartOptions: {
          title: {
            useHTML: true,
            text: `<div>
<b style="margin: 0; margin-bottom: 10px; font-size: 12px">${this.chartTitle}</b>
</div>`,
            align: "left"
          },
          caption: {
            useHTML: true,
            text: ` ${Highcharts.dateFormat("%d %b %Y, %H:%M", Date.now())}`,
            align: "left"
          },
          subtitle: {
            useHTML: true,
            text: this.exportTitle,
            align: "left"
          }
        }
      }
    } as Options);
    return chart;
  }
}
