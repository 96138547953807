import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { ChatsService } from "../../api";

@Component({
  selector: "app-chats-page",
  templateUrl: "./chats-page.component.html",
  styleUrls: ["./chats-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ChatsPageComponent implements OnInit {
  constructor(private api: ChatsService, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {}
}
