import { HttpClient } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-mailing-list-page",
  templateUrl: "./mailing-list-page.component.html",
  styleUrl: "./mailing-list-page.component.scss",
  standalone: false
})
export class MailingListPageComponent implements OnInit {
  list: any[] = [];

  constructor(private httpClient: HttpClient, private matSnackBar: MatSnackBar) {}

  public ngOnInit() {
    this.reload();
  }

  reload() {
    this.httpClient.get("/api/sender").subscribe({
      next: (response: any) => {
        this.list = response;
      },
      error: error => {
        this.matSnackBar.open(error.error.message, "OK", { duration: 3000 });
      }
    });
  }

  queue(id: string) {
    this.httpClient.patch(`/api/sender/${id}/queue`, {}).subscribe({
      next: () => {
        this.matSnackBar.open("Queued", "OK", { duration: 3000 });
        this.reload();
      },
      error: error => {
        this.matSnackBar.open(error.error.message, "OK", { duration: 3000 });
      }
    });
  }

  sentPercent(item: any) {
    if (!item.sent) {
      return 0;
    }
    return (item.sent / item.countIds) * 100;
  }

  successPercent(item: any) {
    if (!item.sent) {
      return 0;
    }
    return ((item.successCount || 1) / item.sent) * 100;
  }

  errorPercent(item: any) {
    if (!item.sent) {
      return 0;
    }
    return ((item.errorCount || 1) / item.sent) * 100;
  }
}
