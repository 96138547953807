import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { DateTime } from "luxon";

@Component({
  selector: "app-requests-page",
  templateUrl: "./requests-page.component.html",
  styleUrls: ["./requests-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class RequestsPageComponent implements OnInit {
  dateStart =
    this.route.snapshot.queryParamMap.get("dateStart") ||
    DateTime.utc()
      .minus({ days: 1 })
      .startOf("day")
      .toISO();
  dateEnd = this.route.snapshot.queryParamMap.get("dateEnd")!;
  limit = +this.route.snapshot.queryParamMap.get("limit")! || 1000;
  zombieId = this.route.snapshot.queryParamMap.get("zombieId") as any;

  constructor(private router: Router, public route: ActivatedRoute, private title: Title) {}

  ngOnInit(): void {
    this.title.setTitle(`Requests list`);
  }
}
