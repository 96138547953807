import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { ZombieService } from "../api";

@Injectable({
  providedIn: "root"
})
export class ZombieResolver {
  constructor(private api: ZombieService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.api.getZombie(+route.paramMap.get("zombieId")!);
  }
}
