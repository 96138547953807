<div>
  <h1>Create new mailing</h1>
</div>

<div>
  <form [formGroup]="formGroup" class="form-container">
    <mat-form-field appearance="outline">
      <mat-label>Bot Token</mat-label>
      <input matInput formControlName="botToken" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>User ids list</mat-label>
      <textarea matInput formControlName="ids" rows="3" placeholder="123,345,456,333,444,555"></textarea>
      <mat-hint>Comma separated list of user ids</mat-hint>
      <mat-hint align="end">{{ idsCount }} - count</mat-hint>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Message id</mat-label>
      <input matInput formControlName="messageId" />
      <a
        mat-icon-button
        matSuffix
        href="https://t.me/damnsenderbot?start=new-message"
        target="_blank"
        matTooltip="Create new message"
      >
        <mat-icon>open_in_new</mat-icon>
      </a>
    </mat-form-field>
    <div *ngIf="message">
      <mat-chip *ngIf="message.photoId">PHOTO</mat-chip>
      <mat-chip *ngIf="message.videoId">VIDEO</mat-chip>
      <mat-chip *ngIf="message.animationId">ANIMATION</mat-chip>
      <span *ngIf="message.text" style="padding-left: 10px">{{ message.text }}</span>
    </div>

    <mat-form-field appearance="outline">
      <mat-label>Your id, for preview</mat-label>
      <input matInput formControlName="previewUserId" />
      <a
        mat-icon-button
        matSuffix
        href="https://t.me/myidbot"
        target="_blank"
        matTooltip="Get my id"
      >
        <mat-icon>open_in_new</mat-icon>
      </a>
    </mat-form-field>

    <button mat-flat-button color="acent"
            [disabled]="!formGroup.value.messageId || !formGroup.value.previewUserId || !formGroup.value.botToken || sendPreviewLoading"
            (click)="sendPreview()">
      Preview, send to me
    </button>
    <button mat-flat-button color="acent" [disabled]="formGroup.invalid || createLoading" (click)="create()">Save
    </button>
  </form>
</div>


