import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { FormControl, FormGroup, RequiredValidator, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";

function bsonObjectIdValidator(control: any) {
  const bsonRegex = /^[a-f\d]{24}$/i; // BSON ObjectId is a 24-character hex string
  return bsonRegex.test(control.value) ? null : { invalidObjectId: true };
}

function botTokenValidator(control: any) {
  const botTokenRegex = /^\d{5,12}:[A-Za-z0-9_-]{20,}$/;
  return botTokenRegex.test(control.value) ? null : { invalidBotToken: true };
}

function idsValidator(control: any) {
  const idsRegex = /^\d+(([,\n])\d+)*$/; // Numbers separated by commas
  return idsRegex.test(control.value.trim()) ? null : { invalidIds: true };
}

export interface MessageModel {
  _id?: string;
  text?: string;
  photoId?: string;
  videoId?: string;
  animationId?: string;
  entities?: any[];
  inline_keyboard?: { url: string; text: string }[][];
}

@Component({
  selector: "app-sender-page",
  templateUrl: "./sender-page.component.html",
  styleUrls: ["./sender-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class SenderPageComponent implements OnInit {
  formGroup = new FormGroup({
    botToken: new FormControl("", [Validators.required, botTokenValidator]),
    ids: new FormControl("", [Validators.required, idsValidator]),
    messageId: new FormControl("", [Validators.required, bsonObjectIdValidator]),
    previewUserId: new FormControl(localStorage.getItem("previewUserId") || "", [Validators.required])
  });

  message: MessageModel | null = null;
  sendPreviewLoading = false;
  createLoading = false;

  constructor(
    private httpClient: HttpClient,
    private matSnackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.formGroup.controls.messageId.valueChanges.subscribe(value => {
      if (value) {
        this.httpClient.get(`/api/sender/message/${value}`).subscribe(response => {
          this.message = response;
        });
      } else {
        this.message = null;
      }
    });

    this.formGroup.controls.previewUserId.valueChanges.subscribe(value => {
      if (value) {
        localStorage.setItem("previewUserId", value);
      } else {
        localStorage.removeItem("previewUserId");
      }
    });
  }

  get idsCount() {
    if (!this.formGroup.value.ids?.trim()) {
      return 0;
    }
    return this.formGroup.value.ids
      ?.trim()
      .split(",")
      .flatMap(x => x.split("\n"))
      .map(id => parseInt(id))
      .filter(id => !isNaN(id)).length;
  }

  sendPreview() {
    this.sendPreviewLoading = true;
    this.httpClient
      .post("/api/sender/preview", {
        botToken: this.formGroup.value.botToken,
        messageId: this.formGroup.value.messageId,
        previewUserId: this.formGroup.value.previewUserId
      })
      .subscribe({
        next: () => {
          this.matSnackBar.open("Preview sent", "OK", { duration: 3000 });
        },
        complete: () => {
          this.sendPreviewLoading = false;
        }
      });
  }

  create() {
    this.createLoading = true;
    this.httpClient
      .post("/api/sender", {
        botToken: this.formGroup.value.botToken,
        messageId: this.formGroup.value.messageId,
        ids: this.formGroup.value.ids
      })
      .subscribe({
        next: () => {
          this.matSnackBar.open("Created", "OK", { duration: 2000 });
          this.router.navigate([".."], { relativeTo: this.route });
        },
        error: error => {
          this.matSnackBar.open(error.error.message, "OK", { duration: 3000 });
          this.createLoading = false;
        },
        complete: () => {
          this.createLoading = false;
        }
      });
  }
}
