import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-inline-loader",
  templateUrl: "./inline-loader.component.html",
  styleUrls: ["./inline-loader.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class InlineLoaderComponent implements OnInit {
  @Input() diameter = 40;
  @Input() strokeWidth = 4;

  constructor() {}

  ngOnInit(): void {}
}
