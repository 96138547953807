<div class="header">
  <h1>Mailing list</h1>
  <span class="spacer"></span>
  <button mat-flat-button color="" routerLink="new">
    New
  </button>
</div>
<br>

<div class="mat-elevation-z2 card">
  <table class="data-table" style="width: 100%">
    <thead>
    <tr>
      <th>Created</th>
      <th>BotId</th>
      <th>State</th>
      <th>Message Id</th>
      <th>All</th>
      <th>Sent</th>
      <th>Ok/Err</th>
      <th>Ok%/Err%</th>
      <th>Action</th>
    </tr>
    </thead>
    <tr *ngFor="let item of list">
      <td>{{item.createdAt | date}}</td>
      <td>{{item.botToken.split(':')[0]}}</td>
      <td>{{item.state}}</td>
      <td>{{item.messageId}}</td>
      <td>{{item.countIds}}</td>
      <td>{{item.sent || 0}} / {{sentPercent(item)}} %</td>
      <td>{{item.successCount || 0}} / {{item.errorCount || 0}}</td>
      <td>{{successPercent(item).toFixed(1)}}% / {{errorPercent(item).toFixed(1)}}%</td>
      <td>
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="queue(item._id)" *ngIf="item.state !== 'queue'">
            <span>Queue</span>
          </button>
        </mat-menu>
      </td>
    </tr>
  </table>
</div>
