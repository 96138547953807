import { ChangeDetectionStrategy, Component, Input, OnInit, Output } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { debounceTime } from "rxjs/operators";
import { RequestsFilterDto } from "../../../lib/types";

@Component({
  selector: "app-requests-filter",
  templateUrl: "./requests-filter.component.html",
  styleUrls: ["./requests-filter.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class RequestsFilterComponent implements OnInit {
  form: UntypedFormGroup = new UntypedFormGroup({
    zombieId: new UntypedFormControl(),
    dateStart: new UntypedFormControl(),
    dateEnd: new UntypedFormControl(),
    requestLike: new UntypedFormControl(),
    method: new UntypedFormControl(),
    takeMs: new UntypedFormControl(),
    error: new UntypedFormControl(),
    limit: new UntypedFormControl(100)
  });

  @Input() initParams!: RequestsFilterDto;

  @Output() formChanged = this.form.valueChanges.pipe(debounceTime(1000));

  constructor() {}

  ngOnInit(): void {
    this.form.patchValue(this.initParams, { emitEvent: false });
  }
}
