import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { DateTime } from "luxon";

@Component({
  selector: "app-logs-page",
  templateUrl: "./logs-page.component.html",
  styleUrls: ["./logs-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class LogsPageComponent implements OnInit {
  dateStart =
    this.route.snapshot.queryParamMap.get("dateStart") ||
    DateTime.utc()
      .minus({ hour: 2 })
      .startOf("hour")
      .toISO();

  dateEnd = this.route.snapshot.queryParamMap.get("dateEnd")!;
  limit = +this.route.snapshot.queryParamMap.get("limit")! || 1000;
  zombieId = this.route.snapshot.queryParamMap.get("zombieId") as any;
  likeSearch = this.route.snapshot.queryParamMap.get("likeSearch")!;
  highlight = this.route.snapshot.queryParamMap.get("highlight") || undefined;

  constructor(private router: Router, public route: ActivatedRoute, private title: Title) {}

  ngOnInit(): void {
    this.title.setTitle(`Logs list`);
  }

  updateUrl(ev: any) {
    console.log("updateUrl", ev);
    this.router
      .navigate([], {
        queryParams: {
          ...ev,
          highlight: this.highlight
        }
      })
      .catch(e => console.log(e));
  }
}
