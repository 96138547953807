import { ChangeDetectionStrategy, Component, Input, OnInit, Output } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { debounceTime } from "rxjs/operators";
import { LogsFilterDto } from "../../api/model/logsFilterDto";

@Component({
  selector: "app-logs-filter",
  templateUrl: "./logs-filter.component.html",
  styleUrls: ["./logs-filter.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class LogsFilterComponent implements OnInit {
  form: UntypedFormGroup = new UntypedFormGroup({
    zombieId: new UntypedFormControl(),
    dateStart: new UntypedFormControl(),
    dateEnd: new UntypedFormControl(),
    likeSearch: new UntypedFormControl(),
    limit: new UntypedFormControl(100)
  });

  @Input() initParams!: LogsFilterDto;

  @Output() formChanged = this.form.valueChanges.pipe(debounceTime(1000));

  constructor() {}

  ngOnInit(): void {
    this.form.patchValue(this.initParams, { emitEvent: false });
  }
}
