import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Observable } from "rxjs";
import { ChatsService, DashboardParam, DashboardService } from "../../api";

@Component({
  selector: "app-dashboard-page",
  templateUrl: "./dashboard-page.component.html",
  styleUrls: ["./dashboard-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class DashboardPageComponent implements OnInit {
  loopChartData: any = null;
  requestsCountChartData: any = null;
  requestsTimeChartData: any = null;
  joinChatsGraph: any = null;
  createdChatsGraph: any = null;
  components: { title: string; limit: number; req$: Observable<Array<DashboardParam>> }[] = [];

  constructor(
    private api: DashboardService,
    private cd: ChangeDetectorRef,
    private title: Title,
    private chatsService: ChatsService
  ) {}

  ngOnInit(): void {
    this.title.setTitle("Dashboard page");
    this.components.push({ limit: 7, title: "Треккинг", req$: this.api.getTrackedStats() });
    this.components.push({ limit: 7, title: "Зомби", req$: this.api.getZombiesStats() });
    this.components.push({ limit: 7, title: "Обьектов", req$: this.api.getEntitiesStats() });
    this.components.push({ limit: 7, title: "Collector", req$: this.api.getCollectorStats() });
    this.components.push({ limit: 7, title: "Errors 24h", req$: this.api.getCollectorErrors() });
    this.components.push({ limit: 7, title: "Zombie errors 24h", req$: this.api.getZombieErrors() });

    this.api.getLoopChart().subscribe(rs => {
      this.loopChartData = [
        {
          name: "среднее",
          data: Array.from(rs).map((item: any) => {
            return [new Date(item.date).getTime(), item.time];
          }),
          type: "column",
          color: "rgb(155, 209, 40)",
          lineWidth: 2,
          tooltip: {
            valueDecimals: 0
          }
        },
        {
          name: "медиана",
          data: Array.from(rs).map((item: any) => {
            return [new Date(item.date).getTime(), item.median];
          }),
          type: "column",
          color: "rgb(209,119,40)",
          lineWidth: 2,
          tooltip: {
            valueDecimals: 0
          }
        }
      ];
      this.cd.detectChanges();
    });

    this.api.getRequestsCount().subscribe(rs => {
      this.requestsCountChartData = [
        {
          name: "Запросы",
          data: Array.from(rs).map((item: any) => {
            return [new Date(item.date).getTime(), item.all];
          }),
          type: "column",
          color: "rgb(40,141,209)",
          lineWidth: 2,
          tooltip: {
            valueDecimals: 0
          }
        },
        {
          name: "Ошибки",
          data: Array.from(rs).map((item: any) => {
            return [new Date(item.date).getTime(), item.errors];
          }),
          type: "column",
          color: "rgb(173,43,43)",
          lineWidth: 2,
          tooltip: {
            valueDecimals: 0
          }
        }
      ];
      this.cd.detectChanges();
    });

    this.api.getRequestsTime().subscribe(rs => {
      this.requestsTimeChartData = [
        {
          name: "милисекунд",
          data: Array.from(rs).map((item: any) => {
            return [new Date(item.date).getTime(), item.time_ms];
          }),
          type: "column",
          color: "rgb(40,209,170)",
          lineWidth: 2,
          tooltip: {
            valueDecimals: 0
          }
        }
      ];
      this.cd.detectChanges();
    });

    this.chatsService.joinsChart().subscribe((rs: any) => {
      this.joinChatsGraph = [
        {
          name: "Слідкуємо",
          data: Array.from(rs).map((item: any) => {
            return [new Date(item.date).getTime(), item.count];
          }),
          type: "column",
          color: "rgb(40,209,170)",
          lineWidth: 2,
          tooltip: {
            valueDecimals: 0
          }
        }
      ];
      this.cd.detectChanges();
    });
    this.chatsService.createdChart().subscribe((rs: any) => {
      this.createdChatsGraph = [
        {
          name: "Нових чатів",
          data: Array.from(rs).map((item: any) => {
            return [new Date(item.date).getTime(), item.count];
          }),
          type: "column",
          color: "rgb(40,209,170)",
          lineWidth: 2,
          tooltip: {
            valueDecimals: 0
          }
        }
      ];
      this.cd.detectChanges();
    });
  }
}
